import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

import { AppCard } from '../general';
import { ChartSeries } from '../../models/chart/chart';
import { useEffect, useState } from 'react';

const defaultOptions: ApexOptions = {
  chart: {
    redrawOnParentResize: true,
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    type: 'datetime',
  },
  stroke: {
    lineCap: 'round',
    curve: 'smooth',
    width: 4,
  },
  grid: {
    strokeDashArray: 5,
  },
};

const LineChartWidget = (props: {
  className?: string;
  title: string;
  chartHeight?: string;
  chartSeries: ChartSeries[] | undefined;
}): JSX.Element => {
  const { className, title, chartHeight, chartSeries } = props;

  const [series, setSeries] = useState<ApexAxisChartSeries | undefined>([{ data: [] }]);

  useEffect(() => {
    setSeries(chartSeries ?? [{ data: [] }]);
  }, [chartSeries]);

  return (
    <AppCard className={className} title={title}>
      <Chart height={chartHeight ?? '275px'} options={defaultOptions} series={series || [{ data: [] }]} type="line" />
    </AppCard>
  );
};

export default LineChartWidget;
