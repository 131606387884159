import { useState, useRef, useEffect, useCallback } from 'react';
import { render } from "@react-email/render";
import { Messages } from 'primereact/messages';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { AppCard, AppPage } from '../../components/general';
import { EmailType } from '../../models/emails/emailType';
import { useParams } from 'react-router-dom';
import { TabPanel, TabView } from 'primereact/tabview';
import { Email } from '../../models/emails/email';
import LockRequestEmail from '../../emails/LockRequestEmail';
import { Editor, OnMount } from '@monaco-editor/react';

const ViewEmailTemplate = () => {
	const errors = useRef<any>(null);
	const [email, setEmail] = useState<Email | null>(null);
	const [html, setHtml] = useState<string>('');
	const [sourceCode, setSourceCode] = useState<string>("");

	const { emailType } = useParams();

	const loadEmailTemplatePreview = (html: string) => {
		setTimeout(() => {
			let iframe = document.getElementById(`previeeIframe`) as HTMLIFrameElement;
			if (!iframe) {
				return;
			}
			let doc = iframe.contentDocument;
			if (doc) {
				doc.open();
				doc.write(html);
				doc.close();
			}
		});
	}

	const handleReactEditorWillMount = (monaco: typeof import("monaco-editor")) => {
		monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
			noSemanticValidation: true,
			noSyntaxValidation: true,
		});
	};

	const handleHtmlEditorWillMount = (monaco: typeof import("monaco-editor")) => {
		// Configure HTML validation and autocompletion
		monaco.languages.html.htmlDefaults.setOptions({
			format: {
				contentUnformatted: "pre,code,textarea", // Tags to exclude from formatting
				indentInnerHtml: true, // Indent content inside <head> and <body>
				preserveNewLines: true, // Preserve existing line breaks
				endWithNewline: true, // Add a newline at the end of the document
				wrapLineLength: 120, // Wrap lines longer than 120 characters
				unformatted: "b,strong,i,span",
				tabSize: 0,
				insertSpaces: true,
				maxPreserveNewLines: undefined,
				indentHandlebars: true,
				extraLiners: '',
				wrapAttributes: 'auto'
			},
		});
	};

	const handleHtmlEditorDidMount: OnMount = (editor) => {
		// Trigger formatting as soon as the editor is mounted
		if (editor) {
			editor.getAction("editor.action.formatDocument")?.run();
		}
	};

	const getEmail = useCallback((): Email | null => {
		switch (emailType) {
			case EmailType.LockRequestEmail:
				return {
					name: 'LockRequestEmail',
					displayName: 'Lock Request Email',
					description: 'Email sent when a lock request is made',
					type: EmailType.LockRequestEmail,
					templateFileName: 'LockRequestEmail.tsx',
				};
			default:
				return null;
		}
	}, [emailType]);

	const renderEmailPreview = async (email: Email | null): Promise<string> => {
		if (!email) {
			return "";
		}
		switch (email.type) {
			case EmailType.LockRequestEmail:
				return await render(<LockRequestEmail />);
			default:
				return "";
		}
	};

	useEffect(() => {
		const email = getEmail();
		setEmail(email);
		const loadSourceCode = async () => {
			try {
				// Dynamically import the raw source code of the email file
				const module = await import(
          /* webpackChunkName: "emails" */ `!!raw-loader!../../emails/${email?.templateFileName}`
				);
				setSourceCode(module.default); // Access the raw string via `default`
				const html = await renderEmailPreview(email);
				setHtml(html);
				loadEmailTemplatePreview(html);
			} catch (error) {
				console.error(`Error loading source for ${email?.name}:`, error);
				setSourceCode("// Error loading source code");
			}
		};

		loadSourceCode();
	}, [getEmail]);

	return (
		<AppPage title={"Email Template - " + email?.displayName} breadcrumbs="Admin - Email Templates">
			<AppCard>
				<Messages ref={errors} />
				<ConfirmDialog />
				<TabView>
					<TabPanel header="React Code">
						<Editor
							height="calc(100vh - 315px)"
							theme="vs-dark"
							value={sourceCode}
							defaultLanguage="typescript"
							defaultValue={sourceCode}
							beforeMount={handleReactEditorWillMount}
						/>
					</TabPanel>
					<TabPanel header="Html Code">

						<Editor
							height="calc(100vh - 315px)"
							theme="vs-dark"
							value={html}
							defaultLanguage="html"
							defaultValue={html}
							beforeMount={handleHtmlEditorWillMount}
							onMount={handleHtmlEditorDidMount}
						/>
					</TabPanel>
					<TabPanel header="Preview">
						<iframe
							id="previewIframe"
							title='previewIframe'
							srcDoc={html}
							style={{
								width: "100%",
								height: "calc(100vh - 315px)",
								border: "1px solid #ccc",
							}}
						/>
					</TabPanel>
				</TabView>
			</AppCard>
		</AppPage>
	);
};

export default ViewEmailTemplate;



