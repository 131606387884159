/**
 *
 * @param firstName
 * @param lastName
 * @returns First name and last name separated with space
 */
export const getFullName = (firstName: string | null | undefined, lastName: string | null | undefined): string => {
  let fullName = '';
  if (firstName) {
    fullName = firstName;
  }
  if (lastName) {
    if (fullName) {
      fullName = fullName + ' ' + lastName;
    } else {
      fullName = lastName;
    }
  }
  return fullName;
};

/**
 *
 * @param person
 * @returns First name and last name separated with space
 */
export const getPersonsFullName = (person: { firstName: string; lastName: string }): string => {
  if (!person) return '';
  let fullName = getFullName(person.firstName, person.lastName);
  return fullName;
};
