import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Messages } from 'primereact/messages';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

import useEffectOnce from '../../hooks/useEffectOnce';
import UserAccountService from '../../services/userAccountService';
import { AppCard, AppPage } from '../../components/general';
import { DataTable, TableDefinition, DataTableSearchCreateHeader } from '../../components/datatable';
import { UserAccount } from '../../models/user-account';
import TenantService from '../../services/tenantService';

const UserAccounts = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [userAccounts, setUserAccounts] = useState<UserAccount[]>([]);
  const [search, setSearch] = useState<string>('');

  const navigate = useNavigate();

  const userAccountService = UserAccountService.getInstance();
  const tenantService = TenantService.getInstance();

  useEffectOnce(() => {
    getUserAccounts();
  });

  const getUserAccounts = (): void => {
    setLoading(true);
    Promise.all([userAccountService.getAll(), tenantService.getAll()])
      .then((results) => {
        setUserAccounts(
          results[0].map((account) => {
            const match = results[1].find((tenant) => tenant.tenantId === account.tenantId);
            let response: UserAccount = account;
            response.tenantName = match?.tenantName;
            return response;
          })
        );
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onAdd = () => {
    navigate(`/user-accounts/new`);
  };

  const onEdit = (cognitoId: string) => {
    navigate(`/user-accounts/${cognitoId}`);
  };

  const onDelete = (account: UserAccount) => {
    confirmDialog({
      message: `Are you sure you want to delete this user account: ${account.id}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setLoading(true);
        userAccountService
          .delete(account.id!)
          .then(() => getUserAccounts())
          .catch((error) => showError(error.message))
          .finally(() => setLoading(false));
      },
    });
  };

  const actionBodyTemplate = (account: UserAccount) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => onEdit(account.id!)}
          tooltip="Edit User Account"
          tooltipOptions={{ position: 'left' }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() => onDelete(account)}
          tooltip="Delete User Account"
          tooltipOptions={{ position: 'left' }}
          severity="danger"
        />
      </div>
    );
  };

  const tableDef: TableDefinition<UserAccount> = {
    columns: [
      {
        field: 'id',
        header: 'Id',
        dataType: 'text',
      },
      {
        field: 'email',
        header: 'Email',
        dataType: 'text',
      },
      {
        field: 'tenantName',
        header: 'Tenant',
        dataType: 'text',
      },
      {
        field: 'firstName',
        header: 'First Name',
        dataType: 'text',
      },
      {
        field: 'lastName',
        header: 'Last Name',
        dataType: 'text',
      },
      {
        field: 'roles',
        header: 'Roles',
        body: (account: UserAccount) => {
          return account.roles?.map(v => <div>{v}</div>);
        },
        dataType: 'text',
      },
      {
        header: 'Actions',
        body: actionBodyTemplate,
        dataType: 'none',
        sortable: false,
        filter: false,
      },
    ],
  };

  return (
    <AppPage title="User Accounts" breadcrumbs="Admin - User Accounts">
      <AppCard>
        <Messages ref={errors} />
        <ConfirmDialog />
        <DataTable
          loading={loading}
          header={<DataTableSearchCreateHeader onCreate={onAdd} onSearchChange={setSearch} />}
          tableDef={tableDef}
          data={userAccounts}
          globalFilterValue={search}
        />
      </AppCard>
    </AppPage>
  );
};

export default UserAccounts;
