import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';

import mezzoFull from '../../../assets/images/mezzo-full.png';
import { ReactComponent as MenuIcon } from '../../../assets/images/menu-icon.svg';

import { useAuth } from '../../auth/authContext';
import { getPersonsFullName } from '../../../utils/misc-utils';

const Header = (props: { className?: string; isDesktop: boolean; onMenuIconClick: () => void }): JSX.Element => {
  const { className, onMenuIconClick } = props;

  const { isAuthenticated, currentUser, logout } = useAuth();

  const [isDesktop, setIsDesktop] = useState<boolean>(props.isDesktop);

  useEffect(() => {
    setIsDesktop(props.isDesktop);
  }, [props.isDesktop, currentUser]);

  // Get the user's name and initials
  const fullName = currentUser ? getPersonsFullName(currentUser) : 'Current User';
  const initials = fullName
    ? fullName
        .match(/(\b\S)?/g)
        ?.join('')
        .match(/(^\S|\S$)?/g)
        ?.join('')
        .toUpperCase()
    : 'CU';

  // Create a menu reference
  const menu = useRef<Menu>(null);

  const menuItems = [
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => {
        logout();
      },
    },
  ];

  return (
    <div
      className={classNames(
        'flex flex-row justify-between items-center px-3 bg-[var(--topbar-bg-color)] shadow-[0_10px_30px_0_rgba(82,63,105,0.05)]',
        className
      )}
    >
      <div className="flex flex-row items-center gap-2">
        {!isDesktop && (
          <>
            <Button
              className="p-button-text text-gray-500 p-2 hover:text-gray-700 focus:shadow-none"
              onClick={() => onMenuIconClick()}
            >
              <MenuIcon title="menu" />
            </Button>
            <Link to="/">
              <img src={mezzoFull} alt="Logo" style={{ height: '30px' }} />
            </Link>
          </>
        )}
      </div>

      {isAuthenticated && (
        <div className="flex items-center gap-2">
          <Avatar label={initials} shape="circle" className="mr-2" />
          <span>{fullName}</span>
          <Menu model={menuItems} popup ref={menu} />
          <Button
            className="p-button-text text-gray-500 p-2 hover:text-gray-700 focus:shadow-none"
            onClick={(e) => menu.current && menu.current.toggle(e)}
          >
            <i className="pi pi-ellipsis-v"></i>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Header;
