import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import useEffectOnce from '../../hooks/useEffectOnce';
import UserAccountService from '../../services/userAccountService';
import { AppPage, AppCard } from '../../components/general';
import { FormContainer, FormDropdown, FormInputText, FormMultiSelect } from '../../components/form';
import { UserAccount, UserAccountDto } from '../../models/user-account';
import { Tenant } from '../../models/tenant';
import TenantService from '../../services/tenantService';

const EditUserAccount = (): JSX.Element => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tenants, setTenants] = useState<Tenant[]>([]);

  const navigate = useNavigate();
  const { userId } = useParams();

  const userAccountService = UserAccountService.getInstance();
  const tenantService = TenantService.getInstance();

  useEffectOnce(() => {
    let apiCalls: Promise<any>[] = [tenantService.getAll()];
    if (!userId) {
      reset(new UserAccount());
    } else {
      apiCalls.push(userAccountService.get(userId));
    }

    setLoading(true);
    Promise.all(apiCalls)
      .then((results) => {
        setTenants(results[0]);

        if (!!results[1]) {
          reset(results[1]);
        }
      })
      .catch((error) => showError(error.message))
      .finally(() => setLoading(false));
  });

  const validationSchema = yup.object({
    email: yup.string().nullable().required('Email is required').trim(),
    tenantId: yup.string().nullable().required('Tenant is required').trim(),
    firstName: yup.string().nullable().required('First name is required').trim(),
    lastName: yup.string().nullable().required('Last name is required').trim(),
    roles: yup.array().nullable().required('A role is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm<UserAccountDto>({
    resolver: yupResolver<any>(validationSchema),
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSubmit = handleSubmit((account: UserAccountDto) => {
    confirmDialog({
      message: `Are you sure you want to save this account: ${account.tenantId}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const addOrUpdate = !userId ? userAccountService.create(account) : userAccountService.update(account);

        setLoading(true);
        addOrUpdate
          .then(() => {
            navigateToUserAccounts();
          })
          .catch((error) => {
            showError(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  });

  const navigateToUserAccounts = () => {
    navigate('/user-accounts');
  };

  return (
    <AppPage title="User Account Details" breadcrumbs="Admin - User Account Details">
      <ConfirmDialog />
      <AppCard>
        <FormContainer loading={loading} errors={errors} onSubmit={onSubmit} onCancel={navigateToUserAccounts}>
          <div className="!grid grid-cols-1">
            <FormInputText
              control={control}
              errors={formErrors}
              field="id"
              label="ID"
              disabled={true}
            />
            <FormInputText control={control} errors={formErrors} field="email" label="Email" />
            <FormDropdown
              control={control}
              errors={formErrors}
              field="tenantId"
              label="Tenant"
              options={tenants}
              optionLabel="tenantName"
              optionValue="tenantId"
            />
            <FormInputText control={control} errors={formErrors} field="firstName" label="First Name" />
            <FormInputText control={control} errors={formErrors} field="lastName" label="Last Name" />
            <FormMultiSelect
              control={control}
              errors={formErrors}
              field="roles"
              label="Roles"
              options={[{ label: "User", value: "User" }, { label: "Admin", value: "Admin" }, { label: "SuperAdmin", value: "SuperAdmin" }]}
            />
          </div>
        </FormContainer>
      </AppCard>
    </AppPage>
  );
};

export default EditUserAccount;
