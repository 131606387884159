import { ProgressSpinner } from 'primereact/progressspinner';

const LoadingPage = () => {
  return (
    <div className="loading-page">
      <ProgressSpinner />
      <p className="loading-message">Loading...</p>
    </div>
  );
};

export default LoadingPage;
