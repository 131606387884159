export class UserAccountDto {
  id?: string = '';
  email?: string = '';
  tenantId?: string = '';
  firstName?: string = '';
  lastName?: string = '';
  updatedDate?: Date | string;
  updatedBy?: string;
  roles?: string[];
}

export class UserAccount extends UserAccountDto {
  tenantName?: string;
}
