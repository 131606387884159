import ApiService from './apiService';
import { User } from '../models/user';

class SessionService extends ApiService {
  private static instance?: SessionService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!SessionService.instance) {
      SessionService.instance = new SessionService();
    }
    return SessionService.instance;
  }

  getLoggedInUser(): Promise<User> {
    return this.client.post(`${process.env.REACT_APP_API_BASE_URL}/auth/user/active`, {}).then((response) => response.data);
  }
}

export default SessionService;
