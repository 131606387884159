export class Authorization {
  accessToken: string = 'access-token';
  refreshToken: string = 'refresh-token';
  tokenExpiresInMilliseconds: string = 'tokenExpiresInMilliseconds';
  grantType: string = 'grant_type';
  passwordGrantType: string = 'password';
  scope: string = 'scope';
  offlineAccessScope: string = 'offline_access';
}

export class Constants {
  public static authorization: Authorization = new Authorization();
}
