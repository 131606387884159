import { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import useEffectOnce from '../../hooks/useEffectOnce';
import ReportAgencyService from '../../services/reportAgencyService';
import { FormDropdown, FormInputText, FormPassword } from '../../components/form';
import { CreditReportingCompany, CreditType } from '../../models/credit/credit-report-transaction';
import { CreditReportingAgency } from '../../models/credit/credit-reporting-agency';
import { CapitalizeFirstLetter } from '../../utils/string-helper';
import { CreditAccount } from '../../models/client/credit-settings';

const credentialKeysLookup = {
  [CreditReportingCompany.Xactus]: ['username', 'password'],
  [CreditReportingCompany.InformativeResearch]: ['username', 'password', 'clientId'],
  [CreditReportingCompany.FactualData]: ['username', 'password', 'appKey'],
  [CreditReportingCompany.MeridianLink]: ['username', 'password', 'mclInterface'],
};

const EditCreditAccount = (props: {
  entry: CreditAccount;
  onSave: (entry: CreditAccount) => void;
  onCancel: () => void;
}): JSX.Element => {
  const errors = useRef<any>(null);

  const [cras, setCras] = useState<CreditReportingAgency[]>([]);

  const reportAgencytService = ReportAgencyService.getInstance();

  useEffectOnce(() => {
    reportAgencytService
      .getAll()
      .then((response) => {
        setCras(response);
      })
      .catch((error) => showError(error.message));
  });

  useEffect(() => {
    reset(props.entry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.entry]);

  const validationSchema = yup.object({
    creditReportingAgencyId: yup.string().nullable().required('Credit reporting agency is required').trim(),
    creditType: yup.string().nullable().required('Credit type is required').trim(),
    credentials: yup.array().of(
      yup.object({
        key: yup.string().nullable().required(),
        value: yup.string().nullable().required(),
      })
    ),
  });

  const {
    control,
    formState: { errors: formErrors },
    reset,
    getValues,
    setValue,
    trigger,
  } = useForm<CreditAccount>({
    resolver: yupResolver<any>(validationSchema),
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  return (
    <Dialog
      visible={true}
      style={{ width: '50vw' }}
      onHide={() => {}}
      footer={() => (
        <div className="text-right">
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-secondary p-button mr-3"
            onClick={props.onCancel}
            type="button"
          />
          <Button
            label="Save"
            onClick={async () => {
              const isValid = await trigger();

              if (isValid) {
                props.onSave(getValues());
              }
            }}
            icon="pi pi-check"
            className="p-button-primary p-button"
            type="button"
          />
        </div>
      )}
      closable={false}
    >
      <div className="grow p-fluid p-input-filled">
        <FormDropdown
          control={control}
          errors={formErrors}
          field="creditReportingAgencyId"
          label="CRA"
          options={cras}
          optionLabel="creditReportingAgencyName"
          optionValue="creditReportingAgencyId"
          onChange={(e) => {
            setValue('creditReportingAgencyId', e.value);

            const match = cras.find((cra) => cra.creditReportingAgencyId === e.value);
            if (!!match?.creditReportingCompany) {
              const keys = credentialKeysLookup[match.creditReportingCompany as unknown as CreditReportingCompany];
              const creds = keys.map((key) => ({ key: key, value: '' }));
              setValue('credentials', creds);
            } else {
              setValue('credentials', []);
            }
          }}
          showFilter={true}
          disabled={!!props.entry.creditReportingAgencyId}
        />
        <FormDropdown
          control={control}
          errors={formErrors}
          field="creditType"
          label="Credit Type"
          options={Object.keys(CreditType)}
          disabled={!!props.entry.creditReportingAgencyId}
        />
        <Controller
          name="credentials"
          control={control}
          render={({ field }) => (
            <>
              {field.value?.map((key, idx) => {
                return key.key === 'password' ? (
                  <FormPassword
                    key={`cred-${idx}`}
                    control={control}
                    errors={formErrors}
                    field={`credentials.${idx}.value`}
                    label={CapitalizeFirstLetter(key.key!)}
                  />
                ) : (
                  <FormInputText
                    key={`cred-${idx}`}
                    control={control}
                    errors={formErrors}
                    field={`credentials.${idx}.value`}
                    label={CapitalizeFirstLetter(key.key!)}
                  />
                );
              })}
            </>
          )}
        />
      </div>
    </Dialog>
  );
};

export default EditCreditAccount;
