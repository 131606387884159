import axios, { AxiosInstance } from 'axios';
import { ApiError } from '../models/apiError';
import AuthService from './authService';
import { Constants } from '../utils/constants';

abstract class ApiService {
  protected readonly client: AxiosInstance;
  private readonly isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;
  private readonly bypassAuth: boolean;
  private authService: AuthService = AuthService.getInstance();

  protected constructor(baseUrl?: string, bypassAuth: boolean = false) {
    this.client = axios.create({
      baseURL: baseUrl,
    });

    this.bypassAuth = bypassAuth;

    this.applyAuthInterceptor();
    this.applyDateInterceptor();
    this.applyErrorInterceptor();
  }

  private applyDateInterceptor(): void {
    this.client.interceptors.response.use((response) => {
      this.convertDates(response.data);
      return response;
    });
  }

  private applyAuthInterceptor(): void {
    this.client.interceptors.request.use(async (config) => {
      if (this.bypassAuth) {
        return config;
      }

      const isValid = await this.authService.isValid();
      if (!isValid) {
        return config;
      }

      const accessToken = localStorage.getItem(Constants.authorization.accessToken);
      if (accessToken) {
        config.headers!.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });
  }

  private applyErrorInterceptor(): void {
    this.client.interceptors.response.use(
      (next) => {
        return Promise.resolve(next);
      },
      (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const message = `${
            error.response.data?.message || error.response.data?.errors || error.response.data?.title || error.message
          }`;
          throw new ApiError(message, error.response.status);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          throw new ApiError(`A request was made but no response was received. ${error.message} `);
        } else {
          // Something happened in setting up the request that triggered an Error
          throw new ApiError(`${error.message}`);
        }
      }
    );
  }

  private convertDates(obj: { [key: string]: any }) {
    for (const [key, value] of Object.entries(obj)) {
      if (value && typeof value === 'string' && this.isoDateFormat.test(value)) {
        obj[key] = new Date(value);
      } else if (typeof value === 'object') {
        obj[key] = this.convertDates(value);
      }
    }
    return obj;
  }
}

export default ApiService;
