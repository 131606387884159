
import {
	Html,
	Head,
	Body,
	Text,
	Heading,
	Section,
	Row,
	Column,
	Tailwind,
	Hr,
	Font,
	Img,
} from "@react-email/components";
import { LockRequestEmailContext } from "../models/emails/lockRequestEmailContext";
import mezzoIcon from '../assets/images/mezzo-icon.png';
import React, { useState, useEffect } from "react";

interface LockRequestEmailProps {
	context?: LockRequestEmailContext;
}

const context: LockRequestEmailContext = {
	productName: "{{Product.Product.ProductName}}",
	lastUpdated: "{{Product.Product.BasePrices.ActiveDate}}",
	basePrice: "{{BasePrice}}",
	rate: "{{Rate}}",
	totalAdjustment: "{{TotalAdj}}",
	lockTerm: "{{LockTerm}}",
	finalPrice: "{{Price}}",
	lockExp: "{{LockExpiration}}",
	compPercent: "{{compPercent}}",
	compDollars: "{{(Request.Loan.CompensationPrice / 100) * Request.Loan.BaseLoanAmount}}",
	compType: "{{Request.Loan.CompensationType}}",
	compPrice: "{{Request.Loan.CompensationPrice}}",
	loanNumber: "{{Request.Loan.LoanNumber}}",
	loanPurpose: "{{Request.Loan.LoanPurpose}}",
	loanAmount: "{{Request.Loan.BaseLoanAmount}}",
	ltv: "{{Request.Loan.Ltv}}",
	cltv: "{{Request.Loan.Cltv}}",
	address: "{{Request.SubjectProperty.Address}}",
	city: "{{Request.SubjectProperty.City}}",
	state: "{{Request.SubjectProperty.State}}",
	zipCode: "{{Request.SubjectProperty.ZipCode}}",
	purchasePrice: "{{Request.SubjectProperty.PurchasePrice}}",
	appraisedValue: "{{Request.SubjectProperty.AppraisedValue}}",
	propertyType: "{{Request.SubjectProperty.PropertyType}}",
	borrowerLastName: "{{Request.Borrower.LastName}}",
	borrowerCreditScore: "{{Request.Loan.RepresentativeFico}}",
	borrowerDocType: "{{borrowerDocType}}",
	borrowerCitizenship: "{{Request.Borrower.Citizenship}}",
	borrowerSelfEmployed: "{{Request.Borrower.IsSelfEmployed}}",
	borrowerMonthsReserves: "{{Request.Borrower.MonthsReserves}}",
	borrowerDebtToIncomeRatio: "{{Request.Borrower.DebtToIncomeRatio}}",
};

const WelcomeEmail: React.FC<LockRequestEmailProps> = (props: LockRequestEmailProps) => {
	const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(window.innerWidth >= 768);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Html>
			<Head />
			<Font
				fontFamily="Inter"
				fallbackFontFamily="Helvetica"
				webFont={{
					url: "https://rsms.me/inter/font-files/Inter-Regular.woff2",
					format: "woff2",
				}}
				fontWeight={400}
				fontStyle="normal"
			/>
			<Body>
				<Tailwind
					config={{
						theme: {
							extend: {
								colors: {
									brand: "#244058",
								},
							},
						},
					}}
				>
					{/* <Img src={mezzoIcon} alt="Mezzo" width="120" height="120" style={{ display: "block", margin: "0 auto" }} />
					<Text>
						<h4 style={{ display: "block", textAlign: "center", margin: "0 auto" }}>This communication serves as a formal request to initiate a rate lock for the following mortgage loan details:</h4>
						<h4 style={{ display: "block", textAlign: "center", margin: "0 auto" }}>The information below is based on current market conditions and subject to change.  This is not a commitment to lend.  For mortgage professionals only.</h4>
					</Text> */}

					<Section>
						<Row>
							<Img src={mezzoIcon} alt="Mezzo" width="120" height="120" style={{ display: "block", margin: "0 auto" }} />
							<Text className="mt-[16px] text-[16px] leading-[24px] text-gray-500" style={{ display: "block", textAlign: "center", marginBottom: "0" }}>
								This communication serves as a formal request to initiate a rate lock for the following mortgage loan details:
							</Text>
							<Text className="text-[16px] leading-[24px] text-gray-500" style={{ display: "block", textAlign: "center", marginTop: "0" }}>
								The information below is based on current market conditions and subject to change.  This is not a commitment to lend.  For mortgage professionals only.
							</Text>
						</Row>
					</Section>

					{isDesktop ? (
						<Section>
							<Row className="mt-[16px]">
								<Column align="center" className="w-1/2 pr-[12px] align-baseline">
									<Section className="overflow-x-auto rounded-lg shadow-md"
										style={{
											border: "1px solid #dee2e6",
											flex: '1',
											minWidth: '300px',
											fontFamily: 'Inter, sans-serif',
										}}>
										<Row className="bg-brand text-white px-3 py-2 items-center"
											style={{
												padding: "0 12px",
											}}>
											<Column>
												<Heading as="h4">
													{props?.context?.productName || context.productName}
												</Heading>
											</Column>
											<Column className="text-right">
												<Text>
													Last Updated: {context?.lastUpdated || "N/A"}
												</Text>
											</Column>
										</Row>
										<Row className="px-3 py-2"
											style={{
												padding: "0 12px",
											}}>
											<Column>
												<Text >Price: {context?.basePrice}</Text>
												<Hr />
												<Text >Rate: {context?.rate}</Text>
												<Hr />
												<Text >Adjustments: {context?.totalAdjustment}</Text>
												<Hr />
												<Text >Lock Term: {context?.lockTerm}</Text>
												<Hr />
												<Text >Final Price: {context?.finalPrice}</Text>
												<Hr />
												<Text >Lock Exp: {context?.lockExp}</Text>
											</Column>
										</Row>
									</Section>
								</Column>
								<Column align="center" className="w-1/2 align-top">
									<Section className="overflow-x-auto rounded-lg shadow-md"
										style={{
											border: "1px solid #dee2e6",
											flex: '1',
											minWidth: '300px',
											marginTop: '0',
											fontFamily: 'Inter, sans-serif',
										}}>
										<Row className="bg-brand text-white px-3 py-2 items-center"
											style={{
												padding: "0 12px",
											}}>
											<Column>
												<Heading as="h4">
													Compensation
												</Heading>
											</Column>
										</Row>
										<Row className="px-3 py-2"
											style={{
												padding: "0 12px",
											}}>
											<Column>
												<Text >Comp %: {context?.compPercent}</Text>
												<Hr />
												<Text >Comp $: {context?.compDollars}</Text>
												<Hr />
												<Text >Comp Type: {context?.compType}</Text>
												<Hr />
												<Text >Comp Price: {context?.compPrice}</Text>
											</Column>
										</Row>
									</Section>
								</Column>

							</Row>
						</Section>
					) : (
						<>
							<Section className="overflow-x-auto rounded-lg shadow-md mb-[16px]"
								style={{
									border: "1px solid #dee2e6",
									flex: '1',
									minWidth: '300px',
									fontFamily: 'Inter, sans-serif',
								}}>
								<Row className="bg-brand text-white px-3 py-2 items-center"
									style={{
										padding: "0 12px",
									}}>
									<Column>
										<Heading as="h4">
											{props?.context?.productName || context.productName}
										</Heading>
									</Column>
								</Row>
								<Row className="bg-brand text-white px-3 items-center">
									<Column>
										<Text style={{
											marginTop: "0",
											marginBottom: "12px",
										}}>
											Last Updated: {context?.lastUpdated || "N/A"}
										</Text>
									</Column>
								</Row>
								<Row className="px-3 py-2"
									style={{
										padding: "0 12px",
									}}>
									<Column>
										<Text >Price: {context?.basePrice}</Text>
										<Hr />
										<Text >Rate: {context?.rate}</Text>
										<Hr />
										<Text >Adjustments: {context?.totalAdjustment}</Text>
										<Hr />
										<Text >Lock Term: {context?.lockTerm}</Text>
										<Hr />
										<Text >Final Price: {context?.finalPrice}</Text>
										<Hr />
										<Text >Lock Exp: {context?.lockExp}</Text>
									</Column>
								</Row>
							</Section>
							<Section className="overflow-x-auto rounded-lg shadow-md mt-[16px]"
								style={{
									border: "1px solid #dee2e6",
									flex: '1',
									minWidth: '300px',
									marginTop: '0',
									fontFamily: 'Inter, sans-serif',
								}}>
								<Row className="bg-brand text-white px-3 py-2 items-center"
									style={{
										padding: "0 12px",
									}}>
									<Column>
										<Heading as="h4">
											Compensation
										</Heading>
									</Column>
								</Row>
								<Row className="px-3 py-2"
									style={{
										padding: "0 12px",
									}}>
									<Column>
										<Text >Comp %: {context?.compPercent}</Text>
										<Hr />
										<Text >Comp $: {context?.compDollars}</Text>
										<Hr />
										<Text >Comp Type: {context?.compType}</Text>
										<Hr />
										<Text >Comp Price: {context?.compPrice}</Text>
									</Column>
								</Row>
							</Section>
						</>
					)}

					<Section className="overflow-x-auto rounded-lg shadow-md my-[16px]"
						style={{
							border: "1px solid #dee2e6",
							flex: '1',
							minWidth: '300px',
							fontFamily: 'Inter, sans-serif',
						}}>
						<Row className="bg-brand text-white px-3 py-2 items-center"
							style={{
								padding: "0 12px",
							}}>
							<Column>
								<Heading as="h4">
									Adjustments
								</Heading>
							</Column>
						</Row>
						<Row className="px-3 py-2"
							style={{
								padding: "0 12px",
							}}>
							<Column>
								<Text >adfads: asdfadsf</Text>
								<Hr />
								<Text >dafa: dafsadf</Text>
							</Column>
						</Row>
					</Section>

					{isDesktop ? (
						<Section>
							<Row className="mt-[16px]">
								<Column className="w-1/3 pr-[12px] align-top">
									<Section className="overflow-x-auto rounded-lg shadow-md" style={{ border: "1px solid #dee2e6", flex: '1 1 calc(33.33% - 20px)', fontFamily: 'Inter, sans-serif', }}>
										<Row className="bg-brand text-white px-3 py-2"
											style={{
												padding: "0 12px",
											}}>
											<Column>
												<Heading as="h4">
													Loan Info
												</Heading>
											</Column>
										</Row>
										<Row className="px-3 py-2"
											style={{
												padding: "0 12px",
											}}>
											<Column>
												<Text >Loan Number: {context?.loanNumber}</Text>
												<Hr />
												<Text >Loan Purpose: {context?.loanPurpose}</Text>
												<Hr />
												<Text >Loan Amount: {context?.loanAmount}</Text>
												<Hr />
												<Text >LTV / CLTV: {context?.ltv} / {context.cltv}</Text>
											</Column>
										</Row>
									</Section>

								</Column>
								<Column className="w-1/3 pr-[12px] align-top">
									<Section className="overflow-x-auto rounded-lg shadow-md" style={{ border: "1px solid #dee2e6", flex: '1 1 calc(33.33% - 20px)', fontFamily: 'Inter, sans-serif', }}>
										<Row className="bg-brand text-white px-3 py-2"
											style={{
												padding: "0 12px",
											}}>
											<Column>
												<Heading as="h4">
													Property Info
												</Heading>
											</Column>
										</Row>
										<Row className="px-3 py-2"
											style={{
												padding: "0 12px",
											}}>
											<Column>
												<Text >Purchase Price: {context?.purchasePrice}</Text>
												<Hr />
												<Text >Appraised Value: {context?.appraisedValue}</Text>
												<Hr />
												<Text >Property Type: {context?.propertyType}</Text>
												<Hr />
												<Text >Address: {context?.address}</Text>
												<Hr />
												<Text >City: {context?.city}</Text>
												<Hr />
												<Text >State: {context?.state}</Text>
												<Hr />
												<Text >Zip Code: {context?.zipCode}</Text>
											</Column>
										</Row>
									</Section>
								</Column>
								<Column className="w-1/3 align-top">
									<Section className="overflow-x-auto rounded-lg shadow-md" style={{ border: "1px solid #dee2e6", flex: '1 1 calc(33.33% - 20px)', fontFamily: 'Inter, sans-serif', }}>
										<Row className="bg-brand text-white px-3 py-2"
											style={{
												padding: "0 12px",
											}}>
											<Column>
												<Heading as="h4">
													Borrower Info
												</Heading>
											</Column>
										</Row>
										<Row className="px-3 py-2"
											style={{
												padding: "0 12px",
											}}>
											<Column>
												<Text >Last Name: {context?.borrowerLastName}</Text>
												<Hr />
												<Text >Credit Score: {context?.borrowerCreditScore}</Text>
												<Hr />
												<Text >Doc Type: {context?.borrowerDocType}</Text>
												<Hr />
												<Text >Citizenship: {context?.borrowerCitizenship}</Text>
												<Hr />
												<Text >Self-Employed: {context?.borrowerSelfEmployed}</Text>
												<Hr />
												<Text >Months Reserves: {context?.borrowerMonthsReserves}</Text>
												<Hr />
												<Text >DTI: {context?.borrowerDebtToIncomeRatio}</Text>
											</Column>
										</Row>
									</Section>
								</Column>
							</Row>
						</Section>
					) : (
						<>
							<Section className="overflow-x-auto rounded-lg shadow-md mb-[16px]" style={{ border: "1px solid #dee2e6" }}>
								<Row className="bg-brand text-white px-3 py-2"
									style={{
										padding: "0 12px",
									}}>
									<Column>
										<Heading as="h4">
											Loan Info
										</Heading>
									</Column>
								</Row>
								<Row className="px-3 py-2"
									style={{
										padding: "0 12px",
									}}>
									<Column>
										<Text >Loan Number: {context?.loanNumber}</Text>
										<Hr />
										<Text >Loan Purpose: {context?.loanPurpose}</Text>
										<Hr />
										<Text >Loan Amount: {context?.loanAmount}</Text>
										<Hr />
										<Text >LTV / CLTV: {context?.ltv} / {context.cltv}</Text>
									</Column>
								</Row>
							</Section>
							<Section className="overflow-x-auto rounded-lg shadow-md mb-[16px]" style={{ border: "1px solid #dee2e6" }}>
								<Row className="bg-brand text-white px-3 py-2"
									style={{
										padding: "0 12px",
									}}>
									<Column>
										<Heading as="h4">
											Property Info
										</Heading>
									</Column>
								</Row>
								<Row className="px-3 py-2"
									style={{
										padding: "0 12px",
									}}>
									<Column>
										<Text >Purchase Price: {context?.purchasePrice}</Text>
										<Hr />
										<Text >Appraised Value: {context?.appraisedValue}</Text>
										<Hr />
										<Text >Property Type: {context?.propertyType}</Text>
										<Hr />
										<Text >State: {context?.state}</Text>
									</Column>
								</Row>
							</Section>
							<Section className="overflow-x-auto rounded-lg shadow-md" style={{ border: "1px solid #dee2e6" }}>
								<Row className="bg-brand text-white px-3 py-2"
									style={{
										padding: "0 12px",
									}}>
									<Column>
										<Heading as="h4">
											Borrower Info
										</Heading>
									</Column>
								</Row>
								<Row className="px-3 py-2"
									style={{
										padding: "0 12px",
									}}>
									<Column>
										<Text >Last Name: {context?.borrowerLastName}</Text>
										<Hr />
										<Text >Credit Score: {context?.borrowerCreditScore}</Text>
										<Hr />
										<Text >Doc Type: {context?.borrowerDocType}</Text>
										<Hr />
										<Text >Citizenship: {context?.borrowerCitizenship}</Text>
										<Hr />
										<Text >Self-Employed: {context?.borrowerSelfEmployed}</Text>
										<Hr />
										<Text >Months Reserves: {context?.borrowerMonthsReserves}</Text>
										<Hr />
										<Text >DTI: {context?.borrowerDebtToIncomeRatio}</Text>
									</Column>
								</Row>
							</Section>
						</>
					)}
				</Tailwind>
			</Body>
		</Html >
	);
};

export default WelcomeEmail;
