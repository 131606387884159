import { Tenant } from '../models/tenant';
import { AuthResult } from '../models/authResult';
import axios, { AxiosInstance } from 'axios';
import { Constants } from '../utils/constants';

class AuthService {
  private readonly client: AxiosInstance;
  private static instance?: AuthService;

  private constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    });
  }

  static getInstance() {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  login(username: string, password: string): Promise<AuthResult> {
    const params = new URLSearchParams();
    params.append(Constants.authorization.grantType, Constants.authorization.passwordGrantType);
    params.append('username', username);
    params.append('password', password);
    params.append(Constants.authorization.scope, Constants.authorization.offlineAccessScope);

    return this.client.post('auth/connect/centro/token', params).then((response) => {
      this.cacheTokenInfo(response.data);
      return response.data;
    });
  }

  getPublicPageDataByUrl(): Promise<Tenant> {
    const domainWithPort = window.location.port
      ? `${window.location.hostname}:${window.location.port}`
      : window.location.hostname;
    const requestBody = '"' + domainWithPort + '"';

    return this.client
      .post(`auth/page-data/url`, requestBody, {
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
      })
      .then((response) => response.data);
  }

  isValid(): Promise<boolean> {
    const accessToken = localStorage.getItem(Constants.authorization.accessToken);
    if (accessToken) {
      const tokenExpiresInMilliseconds = localStorage.getItem(Constants.authorization.tokenExpiresInMilliseconds);
      if (!!tokenExpiresInMilliseconds && Number(tokenExpiresInMilliseconds) > new Date().getTime()) {
        return Promise.resolve(true);
      }
      const refreshToken = localStorage.getItem(Constants.authorization.refreshToken);
      if (!refreshToken) {
        return Promise.resolve(false);
      }
      return this.refresh(refreshToken)
        .then(() => true)
        .catch(() => {
          // If refresh token fails, remove all tokens and navigate to login page
          this.clearTokenInfoFromCache();
          window.location.href = '/login';
          return false;
        });
    }
    return Promise.resolve(false);
  }

  private refresh(refreshToken: string): Promise<AuthResult> {
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', refreshToken);
    params.append('scope', 'offline_access');

    return this.client.post('auth/connect/centro/token', params).then((response) => {
      this.cacheTokenInfo(response.data);
      return response.data;
    });
  }

  private cacheTokenInfo = (authResult: AuthResult) => {
    const now = new Date().getTime();
    // this adds the expires in (expires_in is in seconds) to the current time and subtracts 5 minutes.
    const tokenExpiresInMilliseconds = now + (authResult.expires_in ?? 0) * 1000 - 300000;
    localStorage.setItem(Constants.authorization.tokenExpiresInMilliseconds, tokenExpiresInMilliseconds.toString());
    localStorage.setItem(Constants.authorization.accessToken, authResult.access_token!);
    localStorage.setItem(Constants.authorization.refreshToken, authResult.refresh_token!);
  };

  private clearTokenInfoFromCache = () => {
    localStorage.removeItem(Constants.authorization.tokenExpiresInMilliseconds);
    localStorage.removeItem(Constants.authorization.accessToken);
    localStorage.removeItem(Constants.authorization.refreshToken);
  };
}

export default AuthService;
