import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

import { AppCard } from '../general';
import { PieSeries } from '../../models/chart/chart';
import { useEffect, useState } from 'react';

const defaultOptions: ApexOptions = {
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
};

const PieChartWidget = (props: {
  className?: string;
  title: string;
  chartHeight?: string;
  pieSeries: PieSeries | undefined;
}): JSX.Element => {
  const { className, title, chartHeight, pieSeries } = props;

  const [series, setSeries] = useState<ApexNonAxisChartSeries | undefined>([]);
  const [options, setOptions] = useState<ApexOptions | undefined>(defaultOptions);

  useEffect(() => {
    if (!pieSeries) {
      setSeries([]);
      setOptions(defaultOptions);
      return;
    }

    setSeries(pieSeries.data || [{ data: [] }]);
    setOptions({ ...defaultOptions, labels: pieSeries.labels || [] });
  }, [pieSeries]);

  return (
    <AppCard className={className} title={title}>
      <Chart height={chartHeight ?? '275px'} options={options} series={series || []} type="pie" />
    </AppCard>
  );
};

export default PieChartWidget;
