import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useAuth } from '../components/auth/authContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormInputText from '../components/form/FormInputText';
import FormPassword from '../components/form/FormPassword';
import mezzoFull from '../assets/images/mezzo-full.png'; // Adjust the path as necessary
import { useState } from 'react';
import { Message } from 'primereact/message';

const Login = (): JSX.Element => {
  const [loggingIn, setLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { login } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  const validationSchema = yup.object({
    username: yup.string().nullable().required('Please enter your username').trim(),
    password: yup.string().nullable().required('Please enter your password').trim(),
  });

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm<{ username: string; password: string }>({
    resolver: yupResolver<any>(validationSchema),
  });

  // Extract the returnUrl from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const returnUrl = queryParams.get('returnUrl') || '/';

  const onSubmit = async (credentials: { username: string; password: string }) => {
    try {
      setLoggingIn(true);
      setErrorMessage(null);
      await login(credentials.username, credentials.password);
      navigate(returnUrl); // Redirect to the returnUrl
    } catch (error) {
      setErrorMessage('Invalid credentials. Please try again.');
    } finally {
      setLoggingIn(false);
    }
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <div className="logo-container p-text-center">
          <Link to="/">
            <img src={mezzoFull} alt="Logo" className="logo" />
          </Link>
        </div>
        {errorMessage && (
          <div className="p-fluid p-mb-3">
            <Message severity="error" className="mb-3" text={errorMessage} />
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grow p-fluid p-input-filled">
            <FormInputText
              key={'username'}
              control={control}
              errors={formErrors}
              field={'username'}
              label={'Username'}
              disabled={loggingIn}
            />

            <FormPassword
              key={'password'}
              control={control}
              errors={formErrors}
              field={'password'}
              label={'Password'}
              disabled={loggingIn}
            />

            <Button
              label={loggingIn ? 'Please wait...' : 'Login'}
              icon={loggingIn ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'}
              className="p-button-block p-mt-3"
              type="submit"
              loading={loggingIn} // Show the loading spinner
            />
          </div>
        </form>
      </Card>
    </div>
  );
};

export default Login;
