import { createContext, useContext, useState } from 'react';
import { ReactNode } from 'react';
import AuthService from '../../services/authService';
import SessionService from '../../services/sessionService';
import { User } from '../../models/user';
import useEffectOnce from '../../hooks/useEffectOnce';
import { Constants } from '../../utils/constants';

const AuthContext = createContext<{
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  loading: boolean;
  currentUser: User | null;
}>({
  isAuthenticated: false,
  login: () => Promise.resolve(),
  logout: () => { },
  loading: true,
  currentUser: null,
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  const authService = AuthService.getInstance();
  const sessionService = SessionService.getInstance();

  // Check for an existing token when the app loads
  useEffectOnce(() => {
    const accessToken = localStorage.getItem(Constants.authorization.accessToken);
    if (accessToken) {
      setIsAuthenticated(true);
      setCurrentLoggedInUser();
    }
    setLoading(false);
  });

  // Login function
  const login = async (username: string, password: string) => {
    try {
      setLoading(true);

      await authService.login(username, password);

      setIsAuthenticated(true);

      // Get the current user
      setCurrentLoggedInUser();
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem(Constants.authorization.accessToken);
    localStorage.removeItem(Constants.authorization.refreshToken);
    localStorage.removeItem(Constants.authorization.tokenExpiresInMilliseconds);
    setIsAuthenticated(false);
    setCurrentUser(null);
  };

  const setCurrentLoggedInUser = async () => {
    try {
      sessionService.getLoggedInUser().then((user) => {
        setCurrentUser(user);
      });
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  // Provide authentication state and functions to children
  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, loading, currentUser }}>{children}</AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
