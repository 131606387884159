import { Tenant } from '../models/tenant';
import ApiService from './apiService';

class TenantService extends ApiService {
  private static instance?: TenantService;

  private constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  static getInstance() {
    if (!TenantService.instance) {
      TenantService.instance = new TenantService();
    }
    return TenantService.instance;
  }

  getAll(): Promise<Tenant[]> {
    return this.client.get(`/tenants`).then((response) => {
      return response.data;
    });
  }

  get(id: string): Promise<Tenant> {
    return this.client.get(`/tenants/${id}`).then((response) => response.data);
  }

  create(tenant: Tenant): Promise<void> {
    return this.client.post(`/tenants`, tenant);
  }

  update(tenant: Tenant): Promise<void> {
    return this.client.put(`tenants/${tenant.tenantId}`, tenant);
  }

  delete(id: string): Promise<void> {
    return this.client.delete(`/tenants/${id}`);
  }
}

export default TenantService;
