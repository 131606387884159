import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './authContext';

const withGroupAuthorizer = (WrappedComponent: (props?: any) => JSX.Element, groups: Array<string>) => {
  const WithGroupAuthorizer = (WrappedComponent: (props?: any) => JSX.Element, groups: Array<string>) => {
    const { currentUser } = useAuth();

    const userGroups: string[] = currentUser?.roles ?? [];

    if (userGroups && groups.some((g) => userGroups.includes(g))) {
      return (
        <React.Fragment>
          <WrappedComponent />
        </React.Fragment>
      );
    }

    return <Navigate to="/401" />;
  };
  return WithGroupAuthorizer(WrappedComponent, groups);
};

export default withGroupAuthorizer;
