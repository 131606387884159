import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './authContext';
import { ReactNode } from 'react';

interface SecureRouteProps {
  children: ReactNode;
}

function SecureRoute({ children }: SecureRouteProps) {
  const { isAuthenticated, loading } = useAuth();

  const location = useLocation();

  // While loading, don't render anything or show a loading spinner
  if (loading) {
    return <div>Loading...</div>; // You can customize this loading indicator
  }

  if (!isAuthenticated) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to={`/login?returnUrl=${encodeURIComponent(location.pathname)}`} />;
  }

  // If the user is authenticated, render the children components
  return children;
}

export default SecureRoute;
