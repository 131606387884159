import './App.css';

import { Navigate, Route, Routes } from 'react-router-dom';

import AppLayout from './components/layout/AppLayout';
import Dashboard from './pages/Dashboard';
import CreditEmulator from './pages/credit/CreditEmulator';
import CreditAccounts from './pages/credit/CreditAccounts';
import CreditReports from './pages/credit/CreditReports';
import Underwrites from './pages/underwrite/Underwrites';
import Billing from './pages/billing/Billing';
import Clients from './pages/client/Clients';
import EditClient from './pages/client/EditClient';
import EditBranch from './pages/branch/EditBranch';
import UserAccounts from './pages/user-accounts/UserAccounts';
import EditUserAccount from './pages/user-accounts/EditUserAccount';
import CreditReportingAgencies from './pages/credit/CreditReportingAgencies';
import EditCreditReportingAgency from './pages/credit/EditCreditReportingAgency';

import withGroupAuthorizer from './components/auth/withGroupAuthorizer';
import SecureRoute from './components/auth/secureRoute';
import Login from './pages/Login';
import LoadingPage from './pages/Loading';
import ViewEmailTemplate from './pages/email/ViewEmailTemplate';

function App() {
  return (
    <>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="loading" element={<LoadingPage />} />
        <Route element={<AppLayout />}>
          <Route path="" element={<Navigate to="/dashboard" />} />
          <Route
            path="dashboard"
            element={
              <SecureRoute>
                <Dashboard />
              </SecureRoute>
            }
          />
          <Route
            path="credit/emulator"
            element={
              <SecureRoute>
                <CreditEmulator />
              </SecureRoute>
            }
          />
          <Route
            path="credit/accounts"
            element={
              <SecureRoute>
                <CreditAccounts />
              </SecureRoute>
            }
          />
          <Route
            path="credit/transactions"
            element={
              <SecureRoute>
                <CreditReports />
              </SecureRoute>
            }
          />
          <Route
            path="underwrite"
            element={
              <SecureRoute>
                <Underwrites />
              </SecureRoute>
            }
          />
          <Route
            path="billing"
            element={
              <SecureRoute>
                <Billing />
              </SecureRoute>
            }
          />

          <Route path="clients">
            <Route index={true} element={<SecureRoute>{withGroupAuthorizer(Clients, ['SuperAdmin'])}</SecureRoute>} />
            <Route path="new" element={<SecureRoute>{withGroupAuthorizer(EditClient, ['SuperAdmin'])}</SecureRoute>} />
            <Route path=":clientId" element={<SecureRoute>{withGroupAuthorizer(EditClient, ['SuperAdmin'])}</SecureRoute>} />
          </Route>
          <Route path="branches">
            <Route path=":clientId" element={<SecureRoute>{withGroupAuthorizer(EditBranch, ['SuperAdmin'])}</SecureRoute>} />
            <Route
              path=":clientId/:branchId"
              element={<SecureRoute>{withGroupAuthorizer(EditBranch, ['SuperAdmin'])}</SecureRoute>}
            />
          </Route>
          <Route path="user-accounts">
            <Route index={true} element={<SecureRoute>{withGroupAuthorizer(UserAccounts, ['SuperAdmin'])}</SecureRoute>} />
            <Route path="new" element={<SecureRoute>{withGroupAuthorizer(EditUserAccount, ['SuperAdmin'])}</SecureRoute>} />
            <Route
              path=":userId"
              element={<SecureRoute>{withGroupAuthorizer(EditUserAccount, ['SuperAdmin'])}</SecureRoute>}
            />
          </Route>
          <Route path="credit-reporting-agencies">
            <Route
              index={true}
              element={<SecureRoute>{withGroupAuthorizer(CreditReportingAgencies, ['SuperAdmin'])}</SecureRoute>}
            />
            <Route
              path="new"
              element={<SecureRoute>{withGroupAuthorizer(EditCreditReportingAgency, ['SuperAdmin'])}</SecureRoute>}
            />
            <Route
              path=":creditReportingAgencyId"
              element={<SecureRoute>{withGroupAuthorizer(EditCreditReportingAgency, ['SuperAdmin'])}</SecureRoute>}
            />
          </Route>
          <Route path="email-template">
            <Route path=":emailType" element={<SecureRoute>{withGroupAuthorizer(ViewEmailTemplate, ['SuperAdmin'])}</SecureRoute>} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
