import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import useEffectOnce from '../../hooks/useEffectOnce';
import Overlay from './Overlay';
import Header from './header/Header';
import { Sidebar } from './sidebar';

const AppLayout = () => {
  const desktopBreakpoint: number = 768;

  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth >= desktopBreakpoint);
  const [minimized, setMinimized] = useState<boolean>(true);
  const [locked, setLocked] = useState<boolean>(false);

  useEffectOnce(() => {
    const checkDesktop = () => {
      setIsDesktop(window.innerWidth >= desktopBreakpoint);
    };

    window.addEventListener('resize', checkDesktop);

    return () => {
      window.removeEventListener('resize', checkDesktop);
    };
  });

  useEffect(() => {
    setLocked(isDesktop);
    setMinimized(!isDesktop);
  }, [isDesktop]);

  const toggleSidebar = (): void => {
    const state = !locked;
    setMinimized(!state);
    setLocked(state);
  };

  const onSidebarHover = (state: boolean): void => {
    if (!locked) {
      setMinimized(!state);
    }
  };

  return (
    <div id="app_layout">
      {!isDesktop && !minimized && <Overlay onClick={toggleSidebar} />}
      <Sidebar
        className={classNames(
          'fixed top-0 left-0 z-50 h-full w-[var(--sidebar-max-width)] transition-all duration-500 ease',
          minimized && (isDesktop ? '!w-[var(--sidebar-min-width)]' : 'ml-[calc(var(--sidebar-max-width)_*_-1)]')
        )}
        isDesktop={isDesktop}
        minimized={minimized}
        locked={locked}
        onHover={onSidebarHover}
        onMenuIconClick={toggleSidebar}
      />
      <div
        className={classNames(
          'transition-all duration-500 ease',
          isDesktop && (locked ? 'ml-[var(--sidebar-max-width)]' : 'ml-[var(--sidebar-min-width)]')
        )}
      >
        <Header
          className={classNames('sticky top-0 z-40 h-[var(--topbar-height)]')}
          isDesktop={isDesktop}
          onMenuIconClick={toggleSidebar}
        />
        <Outlet />
      </div>
    </div>
  );
};

export default AppLayout;
